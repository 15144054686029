var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { margin: "10px", "text-align": "center" } },
      [
        _c("h3", [
          _vm._v(
            "닥터인포 웹은 URL을 통한 접근을 허용하지 않습니다. 잘못된 접근입니다."
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }